@import "variables";

@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@400;600&display=swap');

body {
  font-family: "IBM Plex Sans", system-ui, -apple-system, "Segoe UI", Roboto, Arial, sans-serif;
  font-size: $font-size;
  font-weight: $font-weight;
  line-height: $line-height;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

body, blockquote, figure {
  margin: 0; }

a {
  color: inherit;
  text-decoration: inherit;
  transition: color .2s;
  &.selected, &:hover {
    color: reduce(100); } }

article a, b, th {
  font-weight: $font-weight + 200; }

strong {
  font-weight: $font-weight + 300; }

h1, h2, h3, h4, h5, h6 {
  font-weight: $heading-weight; }

article a {
  color: $link-color; }

article header a, article footer a {
  font-weight: inherit;
  color: inherit; }

header time {
  color: reduce(100); }

hr {
  border: 1px solid reduce(30);
  margin: 2em 0; }

blockquote {
  border-left: 4px solid $link-color;
  padding-left: 1.5em; }

img {
  border-radius: 2px;
  max-width: 100%;
  height: auto;
  margin: .5em 0; }
