$font-size: calc(0.84em + 0.50vw);
$heading-weight: 300;

@import "basic";
@import "darkmode";
@import "layout";
@import "code";
@import "table";
@import "highlight";
@import "form";

body {
  display: flex;
  min-height: 100vh; }

table {
  width: 100%; }

h1 {
  font-size: 1.8em; }

header h1 {
  margin: 0; }

article header {
  flex-direction: column;
  align-items: inherit; }

article header {
  margin-bottom: 1.0em; }

article header h1 {
  margin: 0.2em 0;
  font-size: 1.9em; }

article footer {
  padding: 1em 0; }

h2::before {
  position: relative;
  right: calc(105%);
  float: right;
  content: '##';
  color: reduce(25);
  display: inline-block; }

h3::before {
  position: relative;
  right: calc(105%);
  float: right;
  content: '###';
  color: reduce(25);
  display: inline-block; }

h4::before {
  position: relative;
  right: calc(105%);
  float: right;
  content: '####';
  color: reduce(25);
  display: inline-block; }

h5::before {
  position: relative;
  right: calc(105%);
  float: right;
  content: '#####';
  color: reduce(25);
  display: inline-block; }

h6::before {
  position: relative;
  right: calc(105%);
  float: right;
  content: '######';
  color: reduce(25);
  display: inline-block; }




nav {
  margin: 0.5em 0; }

body > header h1 {
  width: 100%;
  line-height: 1.8;
  margin-bottom: 0.5em; }



