@import "variables";

@media (prefers-color-scheme: light) {
  body {
    background: $light!important;
    color: black!important; } }

@media (prefers-color-scheme: dark) {
  body {
    background: $dark!important;
    color: white!important; } }
