@import "variables";

pre {
  background: rgba(38, 38, 38, 0.8);
  color: white;
  border-radius: 2px;
  font-size: .8em;
  margin: 1.5em 0;
  padding: .8em 1.2em;
  white-space: pre-wrap; }

p code {
  background: reduce(20);
  opacity: .5;
  border-radius: 2px;
  font-size: .9em;
  margin: 0 .1em;
  padding: .2em .4em; }
